import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Component } from "../../Interface/AppConfig";
import { getSidenavItems } from "../../Redux/Reducer/UserSlicer/helper";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { Link } from "react-router-dom";
import { setCurrentNavItem } from "../../Redux/Reducer/WebsiteSlicer";
import { Timeline } from "@mui/icons-material";

export const MainListItems = () => {
   const dispatch = useAppDispatch();
   const appConfig = useAppSelector((state) => state.user.appConfig);
   const navItems: Component[] | undefined = getSidenavItems(appConfig);
   const getIcon = (item: Component) => {
      switch (item.id) {
         case "detailed-report":
            return <Timeline />;
         case "liveCount":
            return <AssignmentIcon />;

         default:
            return <DashboardIcon />;
      }
   };

   return (
      <>
         {navItems &&
            navItems.map((item) => (
               <Link
                  key={item.id}
                  style={{
                     color: "inherit",
                     textDecoration: "none",
                  }}
                  to={item.property.route || ""}
                  onClick={(_) => dispatch(setCurrentNavItem(item))}
               >
                  <ListItemButton>
                     <ListItemIcon>{getIcon(item)}</ListItemIcon>
                     <ListItemText primary={item.title} />
                  </ListItemButton>
               </Link>
            ))}
      </>
   );
};

export const secondaryListItems = (
   <React.Fragment>
      <ListSubheader component="div" inset>
         Saved reports
      </ListSubheader>
      <ListItemButton>
         <ListItemIcon>
            <AssignmentIcon />
         </ListItemIcon>
         <ListItemText primary="Current month" />
      </ListItemButton>
      <ListItemButton>
         <ListItemIcon>
            <AssignmentIcon />
         </ListItemIcon>
         <ListItemText primary="Last quarter" />
      </ListItemButton>
      <ListItemButton>
         <ListItemIcon>
            <AssignmentIcon />
         </ListItemIcon>
         <ListItemText primary="Year-end sale" />
      </ListItemButton>
   </React.Fragment>
);
