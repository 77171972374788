import { createAsyncThunk } from "@reduxjs/toolkit";
import { backendLogin, fetchUser } from "../../../Backend/AuthAPI";
import { LoginAction } from "../../../Interface/Actions";
import { UserProfile } from "../../../Interface/User";
import { AppDispatch, RootState } from "../../Store";
import { getSidenavItems } from "./helper";
import { setCurrentNavItem } from "../WebsiteSlicer";

export const login = createAsyncThunk<
  UserProfile,
  LoginAction,
  { state: RootState; dispatch: AppDispatch }
>("user/login", async (payload, thunkApi) => {
  try {
    const { email, password } = payload;
    let res = await backendLogin({
      email,
      password,
    });
    if (res.status === 200) {
      localStorage.setItem("userToken", res.data.token);
      setDefaultRoute(thunkApi.dispatch, res.data.user as UserProfile);
      return res.data.user as UserProfile;
    } else {
      return thunkApi.rejectWithValue("error");
    }
  } catch (err: any) {
    return thunkApi.rejectWithValue(err);
  }
});

export const getUser = createAsyncThunk<
  UserProfile,
  undefined,
  { state: RootState; dispatch: AppDispatch }
>("user/getUser", async (payload = undefined, thunkApi) => {
  try {
    let res = await fetchUser();
    if (res.data.status.code === 200) {
      setDefaultRoute(thunkApi.dispatch, res.data.data.user as UserProfile);
      return res.data.data.user as UserProfile;
    } else return thunkApi.rejectWithValue("error");
  } catch (err: any) {
    return thunkApi.rejectWithValue(err);
  }
});

const setDefaultRoute = (dispatch: AppDispatch, user: UserProfile) => {
  dispatch(setCurrentNavItem(getSidenavItems(user.organisation.dashboard_apps[0])![0]));
};
