import { Autocomplete, Chip, Container, Grid, TextField, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "../../Styles/BagCountingStyles.scss";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "bag-counting-display-sdk": React.DetailedHTMLProps<
        any,
        // React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

interface BoxConfig {
  id: string;
  title: string;
  url: string;
}

const LiveCount = () => {
  const [boxList, setBoxList] = useState<BoxConfig[]>([]);

  useEffect(() => {
    const countingSDK: any = document.getElementsByTagName("bag-counting-display-sdk");
    if (countingSDK) countingSDK.socketconfigs = boxList;
    const newEvent = new CustomEvent("CHANGE_SOCKET_URLS", {
      detail: boxList,
    });
    window.dispatchEvent(newEvent);
    // document
    //   .querySelector("bag-counting-display-sdk")
    //   ?.setAttribute("socketconfigs", JSON.stringify(boxList));
  }, [boxList]);

  // console.log(boxList);
  return (
    <>
      <Helmet>
        {/* <script src="http://localhost:51515/BagCountingDisplaySDK.js"></script> */}
        <script src="https://staging.s20.ai/safecam/bag-counting-display-web-sdk-0.1.2"></script>
      </Helmet>
      <>
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3} sx={{ pl: 4, mt: 4, mb: 4 }}>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              defaultValue={[]}
              style={{ width: "100%" }}
              freeSolo
              onChange={(event, newValue) => {
                setBoxList(
                  newValue.map((url) => ({
                    id: url.toString(),
                    title: url.toString(),
                    url: `http://${url.toString()}`,
                  }))
                );
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Host URL"
                  placeholder="Enter the WS url"
                />
              )}
            />
          </Grid>
          <div>
            <bag-counting-display-sdk
              socketconfigs={JSON.stringify(boxList)}
              //  socketConfigs={JSON.stringify([
              //    {
              //      id: "kadappa-box",
              //      title: "Kadappa box",
              //      url: "http://tunnel.s20.ai:29674",
              //    },
              //    // {
              //    //   id: "sathya-s-box",
              //    //   title: "Sathya's box",
              //    //   url: "http://tunnel.s20.ai:20002",
              //    // },
              //  ])}
            />
          </div>
        </Container>
      </>
    </>
  );
};

export default LiveCount;
