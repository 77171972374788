import { Component } from "../Interface/AppConfig";
import apiRoutes from "./Config/URLs";
import { apiConfig, backendInstance } from "./Utils";

export const fetchTableValue = async (table: Component) => {
   const response = await backendInstance
      .post(apiRoutes.backendRoute.organisation + apiRoutes.backendRoute.tableValue, table, apiConfig())
      .catch((err) => {
         return err.response;
      });
   // to return the data from the API
   // ! might change in the future
   return response;
};

export const fetchCustomTableValue = async (table: Component) => {
   const response = await backendInstance.post(table.property.customRoute!, table, apiConfig()).catch((err) => {
      return err.response;
   });

   // to return the data from the API
   // ! might change in the future
   return response;
};
