import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Box from "@mui/material/Box";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { useNavigate } from "react-router-dom";
import { UserStatus } from "../../Interface/User";
import Header from "./Header";
import Sidenav from "./Sidenav";
import { getUser } from "../../Redux/Reducer/UserSlicer/userAsync";

const mdTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const WrapperContent = ({ children }: { children: JSX.Element }) => {
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header />
        <Sidenav />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const Wrapper = ({ children }: { children: JSX.Element }) => {
  const user = useAppSelector((state) => state.user);
  const { status } = user;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (status === UserStatus.LOGGED_IN && localStorage.getItem("userToken")) {
      navigate("/dashboard");
    } else if (status === UserStatus.LOGGED_OUT || status === UserStatus.ERROR) {
      navigate("/login");
    }
  }, [status]);

  React.useEffect(() => {
    if (status !== UserStatus.LOGGED_IN) {
      dispatch(getUser());
    }
  }, []);

  return <WrapperContent {...{ children }} />;
};

export default Wrapper;
