import { AppConfig } from "./AppConfig";

export interface UserState {
  profile?: UserProfile | null;
  appConfig?: AppConfig;
  status: UserStatus;
}

export enum UserStatus {
  LOGGED_IN = "LOGGED_IN",
  LOGGED_OUT = "LOGGED_OUT",
  ERROR = "ERROR",
  LOADING = "LOADING",
}

export interface UserProfile {
  email: string;
  _id: string;
  name: string;
  phone: string;
  organisation: Organisation;
  createdAt: string;
  updatedAt: string;
  profilePicture: string;
  deviceRegToken: string;
}

export interface Organisation {
  dashboard_apps: AppConfig[];
}
