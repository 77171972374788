import { Toolbar, IconButton, Typography, Badge, styled, Grid } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { RootState } from "../../Redux/Store";
import { setSidenavState } from "../../Redux/Reducer/WebsiteSlicer";
import { styleConstants } from "../Utils/Constants";
import { Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const Header = () => {
  const dispatch = useAppDispatch();
  const { isSidenavOpen, currentNavItem } = useAppSelector((state: RootState) => state.website);
  const { profile } = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: styleConstants.drawerWidth,
      width: `calc(100% - ${styleConstants.drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <AppBar position="absolute" open={isSidenavOpen}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={(_) => dispatch(setSidenavState(!isSidenavOpen))}
          sx={{
            marginRight: "36px",
            ...(isSidenavOpen && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          {currentNavItem?.title}
        </Typography>
        <Typography component="h1" variant="body1" color="inherit" noWrap sx={{ mr: 2.5 }}>
          {profile?.name}
        </Typography>
        <IconButton color="inherit" onClick={(_) => logout()}>
          <Logout />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
