import React from "react";
import "./App.scss";
import "./Styles/BagCountingStyles.scss";
import { Route, Routes } from "react-router-dom";
import routes from "./Routes/routes";
import { Provider } from "react-redux";
import { store } from "./Redux/Store";
import Login from "./Components/Auth/Login";
import Wrapper from "./Components/Common/Wrapper";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Routes>
        <Route key="login" path="/login" element={<Login />} />
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={<Wrapper children={<route.component />} />}
            />
          );
        })}
      </Routes>
    </Provider>
  );
};

export default App;
