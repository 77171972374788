import { appConfigConstants } from "../../../Components/Utils/Constants";
import { AppConfig, Component } from "../../../Interface/AppConfig";

export const getSidenavItems = (
  appConfig: AppConfig | undefined
): Component[] | undefined => {
  let navigation: Component[] | undefined = appConfig?.configuration.find(
    (config) => config.id === appConfigConstants.NAVIGATION
  )!.components;
  return navigation;
};
