import { createListenerMiddleware, PayloadAction } from "@reduxjs/toolkit";
import { setCurrentNavItem, setReportDateRange, setTableValue, setTileValue } from ".";
import { Component, Filters } from "../../../Interface/AppConfig";
import { APIStatus } from "../../../Interface/Common";
import { RootState, store } from "../../Store";
import { updateCurrentStatus } from "./helper";
import { getTableAsync, getTileAsync } from "./websiteAsync";
import { appConfigConstants } from "../../../Components/Utils/Constants";
import { DateRangeString } from "../../../Interface/Website";

export const currentViewChangeMW = createListenerMiddleware();
currentViewChangeMW.startListening({
  actionCreator: setCurrentNavItem,

  effect: async (action: PayloadAction<Component>, thunkApi) => {
    let { currentNavItem } = (thunkApi.getState() as RootState).website;
    if (currentNavItem) {
      currentNavItem.property.content?.forEach((content) => {
        content.components.forEach((component) => {
          if (component.type === appConfigConstants.TILE) store.dispatch(getTileAsync(component));
          else if (
            component.type === appConfigConstants.TABLE ||
            component.type === appConfigConstants.CHART
          ) {
            store.dispatch(getTableAsync(component));
          }
        });
      });
    }
  },
});

export const reportDateRangeMW = createListenerMiddleware();
reportDateRangeMW.startListening({
  actionCreator: setReportDateRange,
  effect: async (action: PayloadAction<DateRangeString>, thunkApi) => {
    let { currentNavItem } = (thunkApi.getState() as RootState).website;
    if (currentNavItem) {
      currentNavItem.property.content?.forEach((content) => {
        content.components.forEach((component) => {
          let _component: Component = JSON.parse(JSON.stringify(component));
          if (_component.property.filters?.includes(Filters.DATE_RANGE)) {
            _component.property.dateRange = action.payload;
            if (_component.type === appConfigConstants.TILE)
              store.dispatch(getTileAsync(_component));
            else if (
              _component.type === appConfigConstants.TABLE ||
              _component.type === appConfigConstants.CHART
            ) {
              store.dispatch(getTableAsync(_component));
            }
          }
        });
      });
    }
  },
});

export const getTableRejectedMiddleware = createListenerMiddleware();
getTableRejectedMiddleware.startListening({
  actionCreator: getTableAsync.rejected,
  effect: async (
    action: PayloadAction<
      string | unknown,
      string,
      {
        arg: Component;
      }
    >,
    thunkApi
  ) => {
    let _currentNavItem = (thunkApi.getState() as RootState).website.currentNavItem;
    if (_currentNavItem) {
      _currentNavItem = updateCurrentStatus(_currentNavItem, APIStatus.ERROR, action);
      thunkApi.dispatch(setTableValue(_currentNavItem));
    }
  },
});

export const getTableFullfiledMiddleware = createListenerMiddleware();
getTableFullfiledMiddleware.startListening({
  actionCreator: getTableAsync.fulfilled,
  effect: async (
    action: PayloadAction<
      string,
      string,
      {
        arg: Component;
      }
    >,
    thunkApi
  ) => {
    let _currentNavItem = (thunkApi.getState() as RootState).website.currentNavItem;
    if (_currentNavItem) {
      _currentNavItem = updateCurrentStatus(_currentNavItem, APIStatus.LOADED, action);
      thunkApi.dispatch(setTileValue(_currentNavItem));
    }
  },
});

export const getTablePendingMiddleware = createListenerMiddleware();
getTablePendingMiddleware.startListening({
  actionCreator: getTableAsync.pending,
  effect: async (action, thunkApi) => {
    let _currentNavItem = (thunkApi.getState() as RootState).website.currentNavItem;
    if (_currentNavItem) {
      _currentNavItem = updateCurrentStatus(_currentNavItem, APIStatus.LOADING, action);
      thunkApi.dispatch(setTileValue(_currentNavItem));
    }
  },
});
export const getTileRejectedMiddleware = createListenerMiddleware();
getTileRejectedMiddleware.startListening({
  actionCreator: getTileAsync.rejected,
  effect: async (
    action: PayloadAction<
      string | unknown,
      string,
      {
        arg: Component;
      }
    >,
    thunkApi
  ) => {
    let _currentNavItem = (thunkApi.getState() as RootState).website.currentNavItem;
    if (_currentNavItem) {
      _currentNavItem = updateCurrentStatus(_currentNavItem, APIStatus.ERROR, action);
      thunkApi.dispatch(setTileValue(_currentNavItem));
    }
  },
});

export const getTileFullfiledMiddleware = createListenerMiddleware();
getTileFullfiledMiddleware.startListening({
  actionCreator: getTileAsync.fulfilled,
  effect: async (
    action: PayloadAction<
      string,
      string,
      {
        arg: Component;
      }
    >,
    thunkApi
  ) => {
    let _currentNavItem = (thunkApi.getState() as RootState).website.currentNavItem;
    if (_currentNavItem) {
      _currentNavItem = updateCurrentStatus(_currentNavItem, APIStatus.LOADED, action);
      thunkApi.dispatch(setTileValue(_currentNavItem));
    }
  },
});

export const getTilePendingMiddleware = createListenerMiddleware();
getTilePendingMiddleware.startListening({
  actionCreator: getTileAsync.pending,
  effect: async (action, thunkApi) => {
    let _currentNavItem = (thunkApi.getState() as RootState).website.currentNavItem;
    if (_currentNavItem) {
      _currentNavItem = updateCurrentStatus(_currentNavItem, APIStatus.LOADING, action);
      thunkApi.dispatch(setTileValue(_currentNavItem));
    }
  },
});
