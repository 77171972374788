import * as React from "react";
import * as MUIcon from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Title from "./Title";
import { Component } from "../../Interface/AppConfig";
import { APIStatus } from "../../Interface/Common";
import { Button, createTheme, Grid, Skeleton, ThemeProvider } from "@mui/material";
import { useAppDispatch } from "../../Redux/Hooks";
import { getTileAsync } from "../../Redux/Reducer/WebsiteSlicer/websiteAsync";

interface TileProps {
   tileComponent: Component;
}

interface IconProps {
   icon: keyof typeof MUIcon;
}
const IconComp: React.FC<IconProps> = ({ icon }) => {
   const Icon = MUIcon[icon];
   return <Icon style={{ fontSize: "3em", color: "grey" }} />;
};

const theme = createTheme({
   components: {
      MuiButton: {
         variants: [
            {
               props: {
                  id: "tile-refresh-button",
               },
               style: {
                  width: "fit-content",
                  height: "2em",
                  ".MuiSvgIcon-root": {
                     fontSize: "1.5em !important",
                  },
               },
            },
         ],
      },
   },
});

export default function Tile(props: TileProps) {
   const { tileComponent } = props;
   const dispatch = useAppDispatch();

   const handleRefresh = () => {
      dispatch(getTileAsync(tileComponent));
   };

   const getTileFragments = (status: APIStatus) => {
      switch (status) {
         case APIStatus.LOADING:
            return (
               <>
                  <Skeleton variant="text" />
                  <Skeleton variant="rectangular" height={"100%"} sx={{ mt: "1em" }} />
               </>
            );
         case APIStatus.LOADED:
            return (
               <>
                  <Grid item display={"flex"} justifyContent={"space-between"}>
                     <Title>{tileComponent.title}</Title>
                     {!tileComponent.value && (
                        <Button onClick={handleRefresh} id={"tile-refresh-button"}>
                           <IconComp icon={"Refresh"} />
                        </Button>
                     )}
                  </Grid>
                  <Typography component="p" variant="h4">
                     {(tileComponent.value * (tileComponent.property.multiplier || 1)).toFixed(
                        tileComponent.property.valueRoundOff || 0
                     )}{" "}
                     {tileComponent.property.suffix}
                  </Typography>
                  <Grid position={"absolute"} bottom={10} right={25}>
                     <IconComp icon={tileComponent.property.icon!.detail as keyof typeof MUIcon} />
                  </Grid>
               </>
            );
         case APIStatus.ERROR:
            return (
               <>
                  <Grid item display={"flex"} justifyContent={"space-between"}>
                     <Title>{tileComponent.title}</Title>
                     <Button onClick={handleRefresh} id={"tile-refresh-button"}>
                        <IconComp icon={"Refresh"} />
                     </Button>
                  </Grid>
                  <IconComp icon={"Error"} />
               </>
            );

         default:
            return <></>;
      }
   };
   return <ThemeProvider theme={theme}>{getTileFragments(tileComponent.status!)}</ThemeProvider>;
}
