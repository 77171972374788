import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCustomTableValue, fetchTableValue } from "../../../Backend/TableAPI";
import { fetchTileValue, getCustomTile } from "../../../Backend/TileAPI";
import { Component } from "../../../Interface/AppConfig";

export const getTileAsync = createAsyncThunk<string, Component, { fulfilledMeta: Component; rejectedMeta: Component; rejectValue: string }>(
   "website/tile",
   async (payload, thunkApi) => {
      try {
         let res: any;
         if (payload.property.customRoute) res = await getCustomTile(payload);
         else res = await fetchTileValue(payload);
         if (res.data.status.code === 200) {
            return thunkApi.fulfillWithValue(res.data.data, payload);
         } else return thunkApi.rejectWithValue("0", payload);
      } catch (err: any) {
         return thunkApi.rejectWithValue("0", payload);
      }
   }
);

export const getTableAsync = createAsyncThunk<string, Component, { fulfilledMeta: Component; rejectedMeta: Component; rejectValue: string }>(
   "website/table",
   async (payload, thunkApi) => {
      try {
         let res: any;
         if (payload.property.customRoute) res = await fetchCustomTableValue(payload);
         else res = await fetchTableValue(payload);
         if (res.data.status.code === 200) {
            return thunkApi.fulfillWithValue(res.data.data, payload);
         } else return thunkApi.rejectWithValue("0", payload);
      } catch (err: any) {
         return thunkApi.rejectWithValue("0", payload);
      }
   }
);
