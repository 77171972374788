import { backendInstance, apiConfig } from "./Utils";
import apiRoutes from "./Config/URLs";

export const backendLogin = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const response = await backendInstance
    .post(
      apiRoutes.backendRoute.login,
      {
        email,
        password,
      },
      apiConfig()
    )
    .catch((err) => {
      return err.response;
    });
  // to return the data from the API
  // ! might change in the future
  return response;
};

export const fetchUser = async () => {
  const response = await backendInstance
    .get(apiRoutes.backendRoute.user, apiConfig())
    .catch((err) => {
      return err.response;
    });

  // to return the data from the API
  // ! might change in the future
  return response;
};
