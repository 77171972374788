import * as React from "react";
import { useTheme } from "@mui/material/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import { Component } from "../../Interface/AppConfig";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";

interface ChartProps {
  chartComponent: Component;
}

// Generate Sales Data
function createData(time: string, amount?: number) {
  return { time, amount };
}

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default function Chart(props: ChartProps) {
  const { chartComponent } = props;
  const [alignment, setAlignment] = React.useState("count");
  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment);
  };
  
  if (!chartComponent.value) return <></>;

  return (
    chartComponent.value &&
    chartComponent.value !== 0 &&
    chartComponent.value !== "0" && (
      <>
        {chartComponent.id === "day-wise-count-summary" && (
          <Grid display={"flex"} width={"100%"} justifyContent={"flex-end"} marginBottom={1.5}>
            <ToggleButtonGroup
              size="small"
              color="primary"
              exclusive
              onChange={handleChange}
              aria-label="Platform"
              value={alignment}
            >
              <ToggleButton value="count">Bag Count</ToggleButton>
              <ToggleButton value="volume-dispatch">Volume Dispatch</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={
              chartComponent.value?.map((val: any) => ({
                ...val,
                ...(alignment === "volume-dispatch"
                  ? {
                      total_deepstream_count_absolute: parseInt(
                        (val.total_deepstream_count_absolute * 0.2).toFixed()
                      ),
                    }
                  : {}),
              })) || []
            }
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time"></XAxis>
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="total_deepstream_count_absolute"
              name={alignment === "volume-dispatch" ? "Tons Dispatched" : "Count"}
              fill="#426fd8"
            />
          </BarChart>
        </ResponsiveContainer>
      </>
    )
  );
}
