import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import UserSlicer from "./Reducer/UserSlicer";
import WebSiteSlicer from "./Reducer/WebsiteSlicer";
import {
   currentViewChangeMW,
   getTableFullfiledMiddleware,
   getTablePendingMiddleware,
   getTableRejectedMiddleware,
   getTileFullfiledMiddleware,
   getTilePendingMiddleware,
   getTileRejectedMiddleware,
   reportDateRangeMW,
} from "../Redux/Reducer/WebsiteSlicer/websiteMiddleware";

export const store = configureStore({
   reducer: {
      user: UserSlicer,
      website: WebSiteSlicer,
   },
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
         .prepend(currentViewChangeMW.middleware)
         .prepend(getTileFullfiledMiddleware.middleware)
         .prepend(getTileRejectedMiddleware.middleware)
         .prepend(getTilePendingMiddleware.middleware)
         .prepend(getTableFullfiledMiddleware.middleware)
         .prepend(getTableRejectedMiddleware.middleware)
         .prepend(getTablePendingMiddleware.middleware)
         .prepend(reportDateRangeMW.middleware),
   devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
   ReturnType,
   RootState,
   unknown,
   Action<string>
>;
