export default {
  base_url: "https://safecam.s20.ai/api",
  // base_url: "http://localhost:4000/api",
  backendRoute: {
    login: "/auth",
    logout: "/auth/logout",
    resetPassword: "/auth/resetPassword",
    register: "/auth/signup",
    user: "/auth/me",
    userList: "/organisation/user",
    organisation: "/organisation",
    premise: "/premise",
    premiseList: "/premise-list",
    summary: "/summary",
    tileValue: "/tileValue",
    tableValue: "/tableValue",
    maskAggregate: "/mask-aggregate",
    socialDistanceAggregate: "/social-distance-aggregate",
    pdfreport: "/generate-report",
    exportData: "/export-data",
    alerts: "/alerts",
    configuration: "/configuration",
    covidCases: "/covid/cases",
    fetchFeeds: "/premise/feeds",
    apps: "/apps",
    jobs: "/jobs",
  },
};
