import axios, { AxiosInstance } from "axios";
import apiRoutes from "../Config/URLs";

export const backendInstance: AxiosInstance = axios.create({
  baseURL: apiRoutes.base_url,
  timeout: 300000,
});

export const apiConfig = () => ({
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
    "x-auth-token": localStorage.getItem("userToken"),
  },
  // withCredentials: true,
});
