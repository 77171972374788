export const styleConstants = {
  drawerWidth: 240,
};

export const appConfigConstants = {
  NAVIGATION: "navigation",
  TILE: "tile",
  TABLE: "table",
  CHART: "chart",
};
