import { PayloadAction } from "@reduxjs/toolkit";
import { Component } from "../../../Interface/AppConfig";
import { APIStatus } from "../../../Interface/Common";

export const updateCurrentStatus = (
  currentNavItem: Component,
  status: APIStatus,
  action: PayloadAction<
    string | unknown,
    string,
    {
      arg: Component;
    }
  >
): Component => {
  return {
    ...currentNavItem,
    property: {
      ...currentNavItem.property,
      content: currentNavItem.property.content?.map((content) => ({
        ...content,
        components: content.components.map((component) => {
          if (component.id === action.meta.arg.id)
            return {
              ...action.meta.arg,
              value: action.payload as string,
              status,
            };
          return component;
        }),
      })),
    },
  };
};
