import { Copyright, Edit } from "@mui/icons-material";
import { Toolbar, Container, Grid, Paper, Typography, IconButton, Modal, Box } from "@mui/material";
import TablePaginated from "../Common/TablePaginated";
import Tile from "../Common/Tile";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import React from "react";
import { Component, Filters } from "../../Interface/AppConfig";
import { appConfigConstants } from "../Utils/Constants";
import { DateRangePicker } from "mui-daterange-picker";
import { setReportDateRange } from "../../Redux/Reducer/WebsiteSlicer";
import Chart from "../Common/Chart";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: 2,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  padding: "10px 20px 20px 20px",
};

const Dashboard = () => {
  const { currentNavItem, reportDateRange } = useAppSelector((state) => state.website);
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState<boolean>(false);
  const toggle = () => setOpen(!open);
  const today = new Date();
  const handleComponents = (component: Component) => {
    switch (component.type) {
      case appConfigConstants.TILE:
        return (
          <Grid key={component.id} item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 170,
                position: "relative",
              }}
            >
              <Tile tileComponent={component} />
            </Paper>
          </Grid>
        );
      case appConfigConstants.TABLE:
        return (
          <Grid key={component.id} item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <TablePaginated tableComponent={component} />
            </Paper>
          </Grid>
        );
      case appConfigConstants.CHART:
        return (
          <Grid key={component.id} item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: 500 }}>
              <Chart chartComponent={component} />
            </Paper>
          </Grid>
        );
      default:
        <></>;
    }
  };

  const showDateRangeSelector = () =>
    currentNavItem?.property.content?.find((content) =>
      content.components.find((comp) => comp.property.filters?.includes(Filters.DATE_RANGE))
    );

  const endDateInState = new Date(reportDateRange.endDate);
  //   if (endDateInState.getDate() - 1 >= new Date(reportDateRange.startDate).getDate())
  //     endDateInState.setDate(endDateInState.getDate() - 1);

  console.log({
    startDate: new Date(reportDateRange.startDate.split("T")[0]).toISOString(),
    endDate: new Date(endDateInState.toISOString().split("T")[0]).toISOString(),
  });

  return (
    <>
      <Toolbar />
      <Container id="app-container" maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {showDateRangeSelector() && (
          <Grid container spacing={3}>
            <Grid item width={"100%"} sx={{ mb: 2 }}>
              <Typography
                width={"100%"}
                textAlign={"center"}
                display={"flex"}
                variant="h6"
                alignItems={"center"}
                justifyContent={"center"}
                gap={1.5}
              >
                Report for <b>{new Date(reportDateRange.startDate).toDateString()}</b> to
                <b>{new Date(endDateInState.toISOString().split("T")[0]).toDateString()}</b>
                <IconButton
                  onClick={(_) => setOpen(true)}
                  style={{ marginLeft: "0.75em" }}
                  size="small"
                  color="primary"
                >
                  <Edit />
                </IconButton>
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={3}>
          {currentNavItem?.property.content?.map((content) => (
            <React.Fragment key={content.id}>
              <Grid item width={"100%"}>
                <Typography width={"fit-content"} variant="h5">
                  {content.title}
                </Typography>
                <Typography width={"fit-content"} variant="subtitle2">
                  {content.description}
                </Typography>
              </Grid>
              {content.components.map((component) => handleComponents(component))}
            </React.Fragment>
          ))}
        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>
      <Modal open={open} onClose={(_) => setOpen(false)}>
        <Box sx={modalStyle}>
          <Typography
            width={"100%"}
            textAlign={"center"}
            display={"flex"}
            variant="h6"
            alignItems={"center"}
            justifyContent={"center"}
            gap={1.5}
          >
            Select Date Range
          </Typography>
          <DateRangePicker
            wrapperClassName="date-range-wrapper"
            initialDateRange={{
              startDate: new Date(reportDateRange.startDate.split("T")[0]),
              endDate: new Date(endDateInState.toISOString().split("T")[0]),
              //   endDate: new Date(
              //     new Date(endDateString).setDate(new Date(endDateString).getDate() - 1)
              //   ),
            }}
            open={open}
            toggle={toggle}
            onChange={(range) => {
              const { startDate, endDate } = range;
              startDate?.setHours(5);
              startDate?.setMinutes(30);
              endDate?.setHours(5);
              endDate?.setMinutes(30);
              endDate?.setUTCHours(23, 59, 59, 999); // Set end time to 23:59:59 UTC
              console.log({
                startDate: startDate?.toLocaleString(),
                endDate: endDate?.toISOString(),
              });
              dispatch(
                setReportDateRange({
                  startDate: startDate!.toISOString(),
                  endDate: endDate!.toISOString(),
                })
              );
              setOpen(false);
            }}
            maxDate={today}
          />
        </Box>
      </Modal>
    </>
  );
};

export default Dashboard;
