import { backendInstance, apiConfig } from "./Utils";
import apiRoutes from "./Config/URLs";
import { Component } from "../Interface/AppConfig";

export const fetchTileValue = async (tile: Component) => {
  const response = await backendInstance
    .post(
      apiRoutes.backendRoute.organisation + apiRoutes.backendRoute.tileValue,
      tile,
      apiConfig()
    )
    .catch((err) => {
      return err.response;
    });

  // to return the data from the API
  // ! might change in the future
  return response;
};

export const getCustomTile = async (tile: Component) => {
  const response = await backendInstance
    .post(tile.property.customRoute!, tile, apiConfig())
    .catch((err) => {
      return err.response;
    });

  // to return the data from the API
  // ! might change in the future
  return response;
};

export const fetchUser = async () => {
  const response = await backendInstance
    .get(apiRoutes.backendRoute.user, apiConfig())
    .catch((err) => {
      return err.response;
    });

  // to return the data from the API
  // ! might change in the future
  return response;
};
