import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserProfile, UserState, UserStatus } from "../../../Interface/User";
import { getUser, login } from "./userAsync";

const initialState: UserState = {
  status: UserStatus.LOADING,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (_) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = UserStatus.LOADING;
        state.profile = null;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<UserProfile>) => {
        state.status = UserStatus.LOGGED_IN;
        state.profile = action.payload;
        // to set appConfig after login (affects sidenav icons if commented)
        state.appConfig = action.payload.organisation.dashboard_apps[0];
      })
      .addCase(login.rejected, (state) => {
        state.status = UserStatus.ERROR;
        state.profile = null;
      })
      .addCase(getUser.pending, (state) => {
        state.status = UserStatus.LOADING;
        state.profile = null;
      })
      .addCase(
        getUser.fulfilled,
        (state, action: PayloadAction<UserProfile>) => {
          state.status = UserStatus.LOGGED_IN;
          state.profile = action.payload;
          state.appConfig = action.payload.organisation.dashboard_apps[0];
          // state.appConfig = bagCountingConfig;
        }
      )
      .addCase(getUser.rejected, (state) => {
        state.status = UserStatus.ERROR;
        state.profile = null;
      });
  },
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
