import { Toolbar, IconButton, Divider, List } from "@mui/material";
import { styled } from "@mui/material/styles";
import { setSidenavState } from "../../Redux/Reducer/WebsiteSlicer";
import { MainListItems } from "./listItems";
import MuiDrawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { RootState } from "../../Redux/Store";
import { styleConstants } from "../Utils/Constants";

const Sidenav = () => {
  const dispatch = useAppDispatch();
  const { isSidenavOpen } = useAppSelector((state: RootState) => state.website);
  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: styleConstants.drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
      }),
    },
  }));

  return (
    <Drawer variant="permanent" open={isSidenavOpen}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: [2],
        }}
      >
        SAFECAM
        <IconButton
          onClick={(_) => {
            dispatch(setSidenavState(!isSidenavOpen));
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <MainListItems />
        {/* <Divider sx={{ my: 1 }} /> */}
        {/* {secondaryListItems} */}
      </List>
    </Drawer>
  );
};

export default Sidenav;
