import Dashboard from "../Components/Pages/Dashboard";
import LiveCount from "../Components/Pages/LiveCount";

const routes: any[] = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/live-count",
    component: LiveCount,
  },
];

export default routes;
