import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Component } from "../../../Interface/AppConfig";
import { DateRangeString, Website } from "../../../Interface/Website";
import { DateRange } from "mui-daterange-picker";

const today = new Date();
const todayEnd = new Date();
today?.setHours(5);
today?.setMinutes(30);
todayEnd?.setHours(5);
todayEnd?.setMinutes(30);
todayEnd?.setUTCHours(23, 59, 59, 999); // Set end time to 23:59:59 UTC

const initialState: Website = {
  isSidenavOpen: false,
  reportDateRange: {
    startDate: today.toISOString(),
    endDate: todayEnd.toISOString(),
  },
};

export const websiteSlice = createSlice({
  name: "website",
  initialState,
  reducers: {
    setReportDateRange(state, action: PayloadAction<DateRangeString>) {
      state.reportDateRange = action.payload;
    },
    setSidenavState(state, action: PayloadAction<boolean>) {
      state.isSidenavOpen = action.payload;
    },
    setCurrentNavItem(state, action: PayloadAction<Component>) {
      let _currentNavItem = action.payload;
      state.currentNavItem = _currentNavItem;
    },
    setTileValue(state, action: PayloadAction<Component>) {
      state.currentNavItem = action.payload;
    },
    setTableValue(state, action: PayloadAction<Component>) {
      state.currentNavItem = action.payload;
    },
  },
});

export const {
  setSidenavState,
  setReportDateRange,
  setCurrentNavItem,
  setTileValue,
  setTableValue,
} = websiteSlice.actions;

export default websiteSlice.reducer;
