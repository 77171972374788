import * as React from "react";
import Title from "./Title";
import { Component, ExportType } from "../../Interface/AppConfig";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { CSVLink } from "react-csv";
import { Button, Grid, IconButton } from "@mui/material";
import { Download } from "@mui/icons-material";
import { useAppSelector } from "../../Redux/Hooks";

interface TableProps {
  tableComponent: Component;
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function TablePaginated(props: TableProps) {
  const { tableComponent } = props;
  const { startDate, endDate } = useAppSelector((state) => state.website.reportDateRange);

  const timeConvert = (n: number | string) => {
    var num = parseInt(n.toString());
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rhours === 0) return rminutes + " m";
    return rhours + " h " + rminutes + " m";
  };

  const dateParser = (dateObj: Date) => {
    let timeString = dateObj.toLocaleString(undefined, {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      //   second: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata",
    });
    return timeString.replace("at", "");
  };

  const dateParser_forCSV = (dateObj: Date) => {
    let timeString = dateObj.toLocaleString(undefined, {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      year: "numeric",
      hour12: true,
      timeZone: "Asia/Kolkata",
    });
    return timeString.replace("at", "");
  };

  const dateParserForFileName = (dateObj: Date) => {
    let todaysDate = new Date();
    if (dateObj.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
      return "Today";
    }
    let timeString = dateObj.toLocaleString(undefined, {
      month: "long",
      day: "numeric",
      // hour: "numeric",
      // minute: "2-digit",
      //   second: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata",
    });
    return timeString.replace("at", "");
  };

  const getCSVData = () => {
    if (tableComponent.value?.length && tableComponent.property.columns!.length) {
      let columns = tableComponent.property.columns!;
      const csvArray = [columns.map((col) => col.headerName)];
      tableComponent.value.forEach((val: any) => {
        let rowArr: any[] = [];
        columns.forEach((col) => {
          if (col.type === "date" || col.type === "dateTime") {
            let formatedDate = new Date(val[col.key]);
            rowArr.push(dateParser_forCSV(formatedDate));
          } else if (col.type === "duration") {
            rowArr.push(timeConvert(val[col.key]));
          } else rowArr.push(val[col.key]);
        });
        csvArray.push(rowArr);
      });
      return csvArray;
    }
    return [];
  };
  return (
    tableComponent.value && (
      <>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Title>{tableComponent.title}</Title>
          {tableComponent.property.exportable?.includes(ExportType.CSV) && (
            <IconButton size="small">
              <CSVLink
                data={getCSVData()}
                filename={`S20AI_Kadappa_${tableComponent.id}-${new Date(
                  startDate
                ).toLocaleDateString()}-${new Date(endDate).toLocaleDateString()}`}
              >
                <Download />
              </CSVLink>
            </IconButton>
          )}
        </Grid>
        <div
          style={{
            height: tableComponent.value.length <= 20 ? "auto" : "1000px",
            width: "100%",
          }}
        >
          <DataGrid
            autoHeight={tableComponent.value.length <= 20}
            // slots={{
            //    toolbar: CustomToolbar,
            // }}
            columns={tableComponent.property.columns!.map((col) => ({
              ...col,
              ...(col.type === "date" || col.type === "dateTime"
                ? {
                    valueGetter: ({ row }) => {
                      return new Date(row[col.key]);
                    },
                    valueFormatter: (row) => {
                      return dateParser(row.value);
                    },
                  }
                : {}),
              ...(col.type === "duration"
                ? {
                    valueFormatter: (row: any) => {
                      return timeConvert(row.value);
                    },
                  }
                : {}),
            }))}
            rows={tableComponent.value}
            pagination
            // autoPageSize
            // pageSizeOptions={5}
            pageSizeOptions={[100]}
          />
        </div>
      </>
    )
  );
}
