import { APIStatus } from "./Common";
import { DateRangeString } from "./Website";

export interface safecamApp {
   id: string;
   title: string;
   description: string;
   key: string;
   configuration: [
      {
         id: string;
         title: string;
         description: string;
         components: [
            {
               type: "chart" | "table" | "tile" | "other";
               id: string;
               title: string;
               description: string;
               property: {
                  // possible properties
                  interface?: string;
                  attributeLabel?: string;
                  aggregation: Boolean;
                  type: "aggregation" | "route" | "time-series" | "other";
               };
            }
         ];
      }
   ];
}

export interface AppConfig {
   id: string;
   title: string;
   description: string;
   configuration: Configuration[];
}

export interface Configuration {
   id: string;
   title: string;
   components: Component[];
   description?: string;
}

export interface Component {
   type: string;
   id: string;
   title: string;
   description: string;
   property: Property;
   value?: string | any;
   status?: APIStatus;
}

export interface Property {
   dateRange: DateRangeString;
   icon?: Icon;
   route?: string;
   valueRoundOff?: number;
   content?: Configuration[];
   aggregationType?: string;
   schema?: string;
   attribute?: string;
   customRoute?: string;
   distinct?: boolean;
   type?: string;
   isTimeSeries?: boolean;
   exportable?: ExportType[];
   columns?: any[];
   suffix?: string;
   multiplier?: number;
   filters?: Filters[];
}

export enum Filters {
   DATE_RANGE = "DATE_RANGE",
}

export enum ExportType {
   CSV = "CSV",
}

export interface Icon {
   detail: string;
   type: IconType;
}

export type IconType = "MUI" | "URL";

// export const bagCountingConfig: AppConfig = {
//   id: "bag-counting",
//   title: "Bag Counting",
//   description: "Bag Counting",
//   configuration: [
//     {
//       id: "navigation",
//       title: "Navigation",
//       components: [
//         {
//           type: "nav",
//           id: "dashboard",
//           title: "Dashboard",
//           description: "To view a snapshot of the app",
//           property: {
//             icon: { detail: "DashboardIcon", type: "MUI" },
//             route: "/dashboard",
//             content: [
//               {
//                 id: "snapshot",
//                 title: "Snapshot",
//                 description: "To get the summary of the app",
//                 components: [
//                   {
//                     type: "tile",
//                     id: "bag-count",
//                     title: "Bag Count",
//                     description: "Bag Count",
//                     property: {
//                       icon: { detail: "Work", type: "MUI" },
//                       aggregationType: "count",
//                       schema: "BagCount",
//                     },
//                   },
//                   {
//                     type: "tile",
//                     id: "trucks-dispatched",
//                     title: "Trucks Dispatched",
//                     description: "Trucks Dispatched",
//                     property: {
//                       icon: { detail: "LocalShipping", type: "MUI" },
//                       aggregationType: "count",
//                       schema: "BagCount",
//                       attribute: "vehicle_number",
//                       distinct: true,
//                     },
//                   },
//                   {
//                     type: "tile",
//                     id: "active-belts",
//                     title: "Active Belts",
//                     description: "Active Belts",
//                     property: {
//                       icon: { detail: "LabelImportant", type: "MUI" },
//                       aggregationType: "count",
//                       schema: "BagCount",
//                       attribute: "belt_id",
//                       distinct: true,
//                     },
//                   },
//                   {
//                     type: "tile",
//                     id: "total-dispatched",
//                     title: "Total Dispatched",
//                     description: "Total Dispatched",
//                     property: {
//                       icon: { detail: "DoubleArrow", type: "MUI" },
//                       aggregationType: "count",
//                       schema: "Violation",
//                     },
//                   },
//                 ],
//               },
//               {
//                 id: "bag-count-summary",
//                 title: "Bag Count Summary",
//                 description: "To get the Bag Count Summary",
//                 components: [
//                   {
//                     type: "table",
//                     id: "bag-count-summary",
//                     title: "Bag Count Summary",
//                     description: "To get the Bag Count Summary",
//                     property: {
//                       columns: [
//                         {
//                           id: "truck-no",
//                           headerName: "Truck No",
//                           flex: 1,
//                           key: "vehicle_number",
//                           field: "vehicle_number",
//                         },
//                         {
//                           id: "belt-id",
//                           headerName: "Belt ID",
//                           flex: 1,
//                           key: "belt_id",
//                           field: "belt_id",
//                         },
//                         {
//                           id: "quantity",
//                           headerName: "Quantity (# bags)",
//                           flex: 1,
//                           key: "current_count",
//                           field: "current_count",
//                         },
//                         {
//                           id: "start-time",
//                           headerName: "Start Time",
//                           flex: 1,
//                           key: "started_at",
//                           field: "started_at",
//                         },
//                         {
//                           id: "end-time",
//                           headerName: "End Time",
//                           flex: 1,
//                           key: "finished_at",
//                           field: "finished_at",
//                         },
//                       ],
//                       schema: "BagCount",
//                     },
//                   },
//                 ],
//               },
//               // {
//               //   id: "face-mask-violations",
//               //   title: "Face Mask Violations",
//               //   description: "To get the Face Mask Violations",
//               //   components: [
//               //     {
//               //       type: "chart",
//               //       id: "face-mask-violations",
//               //       title: "Face Mask Violations",
//               //       description: "To get the Face Mask Violations",
//               //       property: {
//               //         type: "bar",
//               //         schema: "mask",
//               //         aggregationType: "count",
//               //         isTimeSeries: true,
//               //       },
//               //     },
//               //   ],
//               // },
//             ],
//           },
//         },
//       ],
//     },
//   ],
// };

export const covidComplyConfig = {
   id: "covid-comply",
   title: "Covid Complience",
   description: "Covid Complience",
   configuration: [
      {
         id: "navigation",
         title: "Navigation",
         components: [
            {
               type: "nav",
               id: "dashboard",
               title: "Dashboard",
               description: "To view a snapshot of the app",
               property: {
                  icon: "BURGER",
                  route: "/dashboard",
                  content: ["summary", "physical-distancing-violations", "face-mask-violations"],
               },
            },
            {
               type: "nav",
               id: "live-view",
               title: "Live View",
               description: "To get the live data of the app",
               property: {
                  icon: "ROUTE",
                  route: "/live-view",
               },
            },
         ],
      },
      {
         id: "summary",
         title: "Summary",
         description: "To get the summary of the app",
         components: [
            {
               type: "tile",
               id: "max-people",
               title: "Max People",
               description: "Max People",
               property: {
                  icon: "PEOPLE",
                  aggregationType: "sum",
                  schema: "LivePeopleCount",
                  attribute: "people_count",
               },
            },
            {
               type: "tile",
               id: "physical-distancing",
               title: "Physical Distancing",
               description: "Physical Distancing",
               property: {
                  icon: "PEOPLE_DISTANCE",
                  aggregationType: "count",
                  schema: "socialDistance",
               },
            },
            {
               type: "tile",
               id: "mask-protection",
               title: "Mask Protection",
               description: "Mask Protection",
               property: {
                  icon: "MASK",
                  aggregationType: "count",
                  schema: "mask",
               },
            },
            {
               type: "tile",
               id: "total-violations",
               title: "Total Violations",
               description: "Total Violations",
               property: {
                  icon: "VIOLATION",
                  aggregationType: "count",
                  schema: "Violation",
               },
            },
         ],
      },
      {
         id: "physical-distancing-violations",
         title: "Physical Distancing Violations",
         description: "To get the Physical Distancing Violations",
         components: [
            {
               type: "chart",
               id: "physical-distancing-violations",
               title: "Physical Distancing Violations",
               description: "To get the Physical Distancing Violations",
               property: {
                  type: "bar",
                  schema: "socialDistance",
                  aggregationType: "count",
                  isTimeSeries: true,
               },
            },
         ],
      },
      {
         id: "face-mask-violations",
         title: "Face Mask Violations",
         description: "To get the Face Mask Violations",
         components: [
            {
               type: "chart",
               id: "face-mask-violations",
               title: "Face Mask Violations",
               description: "To get the Face Mask Violations",
               property: {
                  type: "bar",
                  schema: "mask",
                  aggregationType: "count",
                  isTimeSeries: true,
               },
            },
         ],
      },
   ],
};
